import React, {FunctionComponent, useContext} from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import "./styles.scss";
import {LocalizedContext} from "../../services/LocalizedContextService";

const KritiqueScript: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query RatingReviews {
      Kritique: allSanityRatingReviewsConfig {
        nodes {
          market
          ratingReviewsData {           
            brandId
            kritiqueUrl
            localeId
          }
        }
      }
    }
  `);

  const { market } = useContext(LocalizedContext);
  const filteredConfig = data.Kritique.nodes.filter((node: any) => node.market === market);
  const config = filteredConfig[0]?.ratingReviewsData;

  return (
    <>
      {typeof window !== "undefined" ? (
        !window.kritiqueInitialized && config ? (
          <Helmet>
            <script
              src={`${config.kritiqueUrl}?brandid=${config.brandId}&localeid=${config.localeId}&apikey=${process.env.GATSBY_KRITIQUE_APIKEY}`}
            />
          </Helmet>
        ) : null
      ) : null}
    </>
  );
};

export default KritiqueScript;
